<template>
  <div class="synth-table-container">
    <div style="font-size: 13px; font-weight: 600; padding: 20px; border-bottom: 1px solid #eee">数据报表</div>
    <div class="control">
      <div class="left">
        <a-button type="link" @click="Visible = true"><a-icon type="radar-chart" />维度</a-button>
        <div class="dimension_list">
          <div class="dimension_item" v-for="(item, index) in dimensionArr" :key="item.prop">
            <span>{{ item.name }}</span>
            <span class="dimension_close" @click="handleCloseDimensionItem(index)"><a-icon type="close" /></span>
          </div>
        </div>
      </div>
      <div>
        <a-button type="link" @click="custTagsVisible = true"><a-icon type="edit" />自定义指标</a-button>
        <a-button :loading="downLoading" @click="exportReport" type="link"><a-icon type="download" />导出</a-button>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust synth_table"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        :scroll="{ x: true }"
        @change="handleSort"
        :loading="isLoading"
        bordered
      >
        <!-- 应用 -->
        <div slot="appName" slot-scope="appName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            <a-popover placement="bottom" trigger="hover">
              <template #content>
                <div class="synth-popover">
                  <div class="synth-popover-item">
                    <span>应用：{{ appName }}</span>
                  </div>
                  <div class="synth-popover-item">
                    <span>应用ID：{{ record.appId }}</span>
                    <a-button size="small" type="link" @click="copyId(record.appId)">复制</a-button>
                  </div>
                </div>
              </template>
              <div class="app-name">
                <IosSvg v-if="record.os === 0" style="width: 14px; height: 14px" />
                <AndroidSvg v-if="record.os === 1" style="width: 14px; height: 14px" />
                {{ appName }}
              </div>
            </a-popover>
          </div>
        </div>
        <!-- 广告位 -->
        <div slot="placeName" slot-scope="placeName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            <a-popover placement="bottom" trigger="hover">
              <template #content>
                <div class="synth-popover" style="width: 300px">
                  <div class="synth-popover-item">
                    <span
                    ><span>广告位名称：{{ record.placeName }}</span></span
                    >
                  </div>
                  <div class="synth-popover-item">
                    <span
                    ><span>广告位ID：{{ record.placeId }}</span></span
                    >
                    <a-button size="small" type="link" @click="copyId(record.placeId)">复制</a-button>
                  </div>
                </div>
              </template>
              <div style="max-width: 130px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                {{ placeName }}
              </div>
            </a-popover>
          </div>
        </div>
        <!-- 流量分组 -->
        <div slot="groupName" slot-scope="groupName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            {{ groupName }}
          </div>
        </div>
        <!-- 测试分组 -->
        <div slot="testGroupName" slot-scope="testGroupName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            {{ testGroupName }}
          </div>
        </div>
        <!-- 渠道 -->
        <div slot="channel" slot-scope="channel, record, index">
          <div
            class="fixed_item"
            v-if="index !== 0"
            style="width: 200px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
          >
            {{ channel }}
          </div>
        </div>
        <!-- 广告源 -->
        <div slot="sourceName" slot-scope="sourceName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            <a-popover placement="bottom" trigger="hover">
              <template #content>
                <div class="synth-popover">
                  <div v-if="record.platId !== 99999" class="synth-popover-item">
                    <span>广告平台：{{ record.platName }}</span>
                  </div>
                  <div v-if="record.platId !== 99999" class="synth-popover-item">
                    <span>账号名称：{{ record.platAccountName }}</span>
                  </div>
                  <div class="synth-popover-item">
                    <span>广告源名称：{{ record.sourceName }}<PublicPlaceSvg v-if="record.reuse" style="width: 12px; height: 12px;margin-left:4px"/></span>
                  </div>
                  <div class="synth-popover-item">
                    <span>广告源ID：{{ record.platPlaceId }}</span>
                    <a-button size="small" type="link" @click="copyId(record.platPlaceId)">复制</a-button>
                  </div>
                </div>
              </template>
              {{ sourceName }}
            </a-popover>
          </div>
        </div>
        <!-- os -->
        <template slot="os" slot-scope="OS, record, index">
          <span v-if="index !== 0">{{ OS ? '安卓' : 'IOS' }}</span>
        </template>
        <!-- 广告样式 -->
        <div slot="positionName" slot-scope="positionName, record, index">
          <div class="fixed_item" :title="positionName" v-if="index !== 0">
            {{ positionName }}
          </div>
        </div>
        <!-- sdk版本 -->
        <div slot="sdkVersion" slot-scope="sdkVersion, record, index">
          <div class="fixed_item" :title="sdkVersion" v-if="index !== 0">
            {{ sdkVersion }}
          </div>
        </div>
        <!-- 广告平台 -->
        <div slot="platName" slot-scope="platName, record, index">
          <div class="fixed_item" :title="platName" v-if="index !== 0">
            {{ platName }}
          </div>
        </div>
        <!-- app版本 -->
        <div slot="appVersion" slot-scope="appVersion, record, index">
          <div class="fixed_item" :title="appVersion" v-if="index !== 0">
            {{ appVersion }}
          </div>
        </div>
        <!-- 渗透率 -->
        <div slot="permeability" slot-scope="permeability">
          <div :title="permeability">{{ permeability }}%</div>
        </div>
        <!-- 流量填充率 -->
        <div slot="requestFilledRate" slot-scope="requestFilledRate">
          <div :title="requestFilledRate">{{ requestFilledRate }}%</div>
        </div>
        <!-- 展示率 -->
        <div slot="impressRate" slot-scope="impressRate">
          <div :title="impressRate">{{ impressRate }}%</div>
        </div>
        <!-- 点击率 -->
        <div slot="clickRate" slot-scope="clickRate">
          <div :title="clickRate">{{ clickRate }}%</div>
        </div>
        <!-- 新用户占比 -->
        <div slot="newUserRate" slot-scope="newUserRate">
          <div :title="newUserRate">{{ newUserRate }}%</div>
        </div>
        <!-- 点击率API -->
        <div slot="unitCtr" slot-scope="unitCtr">
          <div :title="unitCtr">{{ unitCtr }}%</div>
        </div>
        <!-- 广告填充率 -->
        <div slot="advRequestFilledRate" slot-scope="advRequestFilledRate">
          <div :title="advRequestFilledRate">{{ advRequestFilledRate }}%</div>
        </div>
        <!-- 展示Gap -->
        <div slot="impressionGap" slot-scope="impressionGap">
          <div :title="impressionGap">{{ impressionGap }}%</div>
        </div>
        <!-- 点击Gap -->
        <div slot="clickGap" slot-scope="clickGap">
          <div :title="clickGap">{{ clickGap }}%</div>
        </div>
        <!-- 展示占比 -->
        <div slot="display" slot-scope="display">
          <div v-if="display || display === 0" :title="display">{{ display }}%</div>
        </div>
        <!-- 展请率 -->
        <div slot="impressRequestRate" slot-scope="impressRequestRate">
          <div :title="impressRequestRate">{{ impressRequestRate }}%</div>
        </div>
        <!-- 收益占比 -->
        <div slot="revenuePercentage" slot-scope="revenuePercentage">
          <div :title="revenuePercentage">{{ revenuePercentage }}%</div>
        </div>
        <!-- 填充API -->
        <div slot="unitFilledRate" slot-scope="unitFilledRate">
          <div :title="unitFilledRate">{{ unitFilledRate }}%</div>
        </div>
        <!-- 利润率 -->
        <div slot="profitRate" slot-scope="profitRate">
          <div :title="profitRate">{{ profitRate }}%</div>
        </div>
        <!-- 点击率（去重） -->
        <div slot="uniqClickRate" slot-scope="uniqClickRate">
          <div :title="uniqClickRate">{{ uniqClickRate }}%</div>
        </div>
      </a-table>
    </div>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 1"
      show-size-changer
      :current="searchquery.page"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
      :pageSize="searchquery.limit"
    />
    <m-empty v-else style="height: 500px">
      <template #description>暂无数据 </template>
    </m-empty>
    <CustDimension
      v-if="Visible"
      :visible="Visible"
      :allDimesion="allDimesion"
      :groupByList="groupByList"
      @modalCancel="Visible = false"
      @changeCustDimension="changeCustDimension"
    />
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :storageName="storageName"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :defaultCheckedList="defaultCheckedList"
    />
  </div>
</template>

<script>
import { getSynthReport } from '@/api/reportdatas'
import CustDimension from './CustDimension'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import excel from '@/utils/excel'
import request from '@/utils/request'
import { copy } from '@/utils/string'
import { numFormat } from '@/utils/dealNumber'
import localDataName from '@/utils/localDataName'
import custTagObj from './localColumns'
import CustTagsModal from '@/components/common/CustTagsModal'
import mixFetchPool from '@/mixins/fetchPool'
import { mapState } from 'vuex'
import PublicPlaceSvg from '@/assets/icons/publicPlace.svg?inline'

export default {
  components: { CustDimension, CustTagsModal, IosSvg, AndroidSvg, PublicPlaceSvg },
  mixins: [mixFetchPool],
  name: 'SynthTable',
  data () {
    return {
      defaultCheckedList: [],
      targetArr: [], // 指标数组
      storageName: localDataName.synthReport, // 综合报表本地缓存key
      custTagsVisible: false, // 自定义指标弹窗是否显示
      sorterItem: null, // 当前排序属性
      groupByList: [], // 当前维度列表
      searchquery: {
        groupByList: ['date'],
        page: 1,
        limit: 10
      },
      dataList: [], // 应用列表
      total: 0,
      isLoading: false,
      AngleShow: [], // 当前维度选择后，不显示的指标
      Visible: false, //
      // 显示talbe（总table）
      columns: [],
      // 维度table
      baseColumns: [
        {
          title: '日期',
          dataIndex: 'date',
          width: 180,
          align: 'left',
          fixed: true
        }
      ],
      // 指标table
      sureColumns: [],
      sureArr: [],
      downLoading: false,
      dimensionArr: [],
      allDimesion: [
        {
          key: 'app_id',
          name: '应用',
          checked: false,
          prop: 'appName',
          width: 120
        },
        {
          key: 'place_id',
          name: '广告位',
          checked: false,
          prop: 'placeName',
          width: 150
        },
        {
          key: 'group_id',
          name: '流量分组',
          checked: false,
          prop: 'groupName',
          width: 120
        },
        {
          key: 'test_id',
          name: 'A/B测试',
          checked: false,
          prop: 'testGroupName',
          width: 100
        },
        {
          key: 'channel',
          name: '渠道',
          checked: false,
          prop: 'channel',
          width: 100
        },
        {
          key: 'ad_source_id',
          name: '广告源',
          checked: false,
          prop: 'sourceName',
          width: 120
        },
        {
          key: 'position_id',
          name: '广告样式',
          checked: false,
          prop: 'positionName',
          width: 80
        },
        {
          key: 'sdk_version',
          name: 'SDK版本',
          checked: false,
          prop: 'sdkVersion',
          width: 80
        },
        {
          key: 'plat_id',
          name: '广告平台',
          checked: false,
          prop: 'platName',
          width: 100
        },
        {
          key: 'app_version',
          name: '应用版本',
          checked: false,
          prop: 'appVersion',
          width: 100
        },
        {
          key: 'plat_account_id',
          name: '广告平台账户',
          checked: false,
          prop: 'platAccountName',
          width: 240
        },
        {
          key: 'country_code',
          name: '地区',
          checked: false,
          prop: 'countryCode',
          width: 160
        }
      ]
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      title: (state) => state.autoweb.title,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      isAdmin: (state) => state.user.isAdmin,
      role: (state) => state.user.roles[0],
      currentLoginId: (state) => state.user.id
    })
  },
  mounted () {
    this.columns = [...this.baseColumns, ...this.sureColumns]
    this.initTargetArr()
    // 跳转来的,携带参数,维度广告位
    Object.keys(this.$route.query).length !== 0 && this.changeGetDimension()
  },
  methods: {
    // 跳转获得广告位
    changeGetDimension () {
      const getArr = this.allDimesion.filter((x) => x.prop === 'placeName')
      getArr.checked = true
      this.changeCustDimension(getArr, true)
    },
    // 修改自定义指标回调
    changeCustTags (targetArr, sureArr) {
      const sortItem = this.columns.find((item) => item.sortOrder)
      if (sortItem) {
        this.sorterItem = sortItem
      } else {
        this.sorterItem = null
      }
      this.sureArr = sureArr
      this.getColumns()
    },
    // 获取指标列表数组
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = custTagObj
      this.defaultCheckedList = JSON.parse(JSON.stringify(sureArr))
      if (this.role !== 'SUPER_ADMIN' && this.currentLoginId !== 25) {
        targetArr.forEach((item) => {
          item.list = item.list.filter((child) => !child.superAdminShow)
        })
      }
      this.targetArr = targetArr
      if (!tagInfo) {
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
    },
    // 获取columns
    getColumns () {
      const arr = []
      this.sureArr.forEach((item) => {
        if (!this.AngleShow.includes(item.prop)) {
          arr.push({
            title: (
              <div>
                <m-tips showIcon={false} content={item.desc} title={item.label} width={150}></m-tips>
              </div>
            ),
            dataIndex: item.prop,
            // width: item.width,
            align: 'left',
            sortDirections: ['descend', 'ascend'],
            sorter: item.sortable,
            // 判断之前的排序
            sortOrder: this.sorterItem && this.sorterItem.dataIndex === item.prop && this.sorterItem.sortOrder,
            scopedSlots: { customRender: item.prop }
          })
        }
      })
      this.sureColumns = arr
      this.sureColumns[0] && (this.sureColumns[0].width = null)
      this.columns = [...this.baseColumns, ...this.sureColumns]
    },
    // 排序处理
    handleSort (pagination, filters, sorter) {
      this.searchquery.sortBy =
        sorter.field === 'income'
          ? '1'
          : sorter.field === 'request'
          ? '2'
          : sorter.field === 'impress'
          ? '3'
          : sorter.field === 'click'
          ? '4'
          : sorter.field === 'advRequest'
          ? '5'
          : sorter.field === 'unitRevenue'
          ? '6'
          : sorter.field === 'unitImpression'
          ? '7'
          : sorter.field === 'unitClick'
          ? '8'
          : ''

      this.searchquery.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
      // 修改columns
      this.columns.forEach((item) => {
        if (item.dataIndex === sorter.field) {
          item.sortOrder = sorter.order
          this.sorterItem = item
        } else {
          item.sortOrder = false
        }
      })
      this.getTableData()
    },
    // 父级调用，处理query
    dealQuery (query) {
      this.searchquery.page = 1
      this.searchquery.sortBy = ''
      this.searchquery.sortOrder = ''
      this.sorterItem = null
      this.searchquery = { ...this.searchquery, ...query }
      this.getColumns()
      this.getTableData()
    },
    // 获取数据
    async getTableData () {
      // 更新axios取消令牌
      this.getCancelToken()
      this.isLoading = true
      const resp = await getSynthReport(this.searchquery, this.cancelSource.token)
      this.total = resp.data ? resp.data.total : 0
      const items = resp.data ? resp.data.items : []
      const sum = resp.data ? resp.data.aggregate : {}
      sum.date = '汇总'
      this.dataList = [sum, ...items]
      this.dataList.length === 1 && (this.dataList = [])
      this.dataList.forEach((item) => {
        item.dau = numFormat(item.dau, 3, '')
        item.arpDeu = numFormat(item.arpDeu, 3, '')
        item.income = numFormat(item.income, 3, '')
        item.aipau = numFormat(item.aipau, 3, '')
        item.estimatedRevenueEcpm = numFormat(item.estimatedRevenueEcpm, 3, '')
        item.request = numFormat(item.request, 3, '')
        item.impress = numFormat(item.impress, 3, '')
        item.unitRevenue = numFormat(item.unitRevenue, 3, '')
        item.unitEcpm = numFormat(item.unitEcpm, 3, '')
        item.newLogin = numFormat(item.newLogin, 3, '')
        item.deu = numFormat(item.deu, 3, '')
        item.click = numFormat(item.click, 3, '')
        item.advRequest = numFormat(item.advRequest, 3, '')
        item.unitImpression = numFormat(item.unitImpression, 3, '')
        item.unitClick = numFormat(item.unitClick, 3, '')
        item.unitRequest = numFormat(item.unitRequest, 3, '')
        item.response = numFormat(item.response, 3, '')
        item.advPrice = numFormat(item.advPrice, 3, '')
        item.uniqClick = numFormat(item.uniqClick, 3, '')
      })
      await this.getShowArr()
      await this.getColumns()
      this.isLoading = false
      this.removeCancelSource()
    },
    // 获取不显示得指标数组
    getShowArr () {
      // 不显示列数组
      this.AngleShow = []
      //  应用
      let apparr = []
      if (this.searchquery.groupByList.includes('app_id') || this.searchquery.appIdList.length > 0) {
        apparr = ['bidSuccessRate']
      }
      // 广告位
      let placearr = []
      if (this.searchquery.groupByList.includes('place_id') || this.searchquery.placeIdList.length > 0) {
        placearr = [
          'newLogin',
          'newUserRate',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
      }
      // 流量分组
      let grouparr = []
      if (this.searchquery.groupByList.includes('group_id') || this.searchquery.groupIdList.length > 0) {
        grouparr = [
          'arpu',
          'arpDeu',
          'unitRevenue',
          'unitEcpm',
          'newLogin',
          'newUserRate',
          'impressionGap',
          'clickGap',
          'unitImpression',
          'unitClick',
          'unitCtr',
          'unitRequest',
          'unitFilledRate',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
      }
      //   ab测试
      let abtestarr = []
      if (this.searchquery.groupByList.includes('test_id')) {
        abtestarr = [
          'arpu',
          'arpDeu',
          'unitRevenue',
          'unitEcpm',
          'newLogin',
          'newUserRate',
          'impressionGap',
          'clickGap',
          'unitImpression',
          'unitClick',
          'unitCtr',
          'unitRequest',
          'unitFilledRate',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
      }
      // 渠道
      let channelarr = []
      if (this.searchquery.groupByList.includes('channel') || this.searchquery.channelIdList.length > 0) {
        channelarr = [
          'arpu',
          'arpDeu',
          'unitRevenue',
          'unitEcpm',
          'impressionGap',
          'clickGap',
          'unitImpression',
          'unitClick',
          'unitCtr',
          'unitRequest',
          'unitFilledRate',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
      }
      //  广告源
      let sourcearr = []
      if (this.searchquery.groupByList.includes('ad_source_id') || this.searchquery.sourceIdList.length > 0) {
        sourcearr = [
          'dau',
          'permeability',
          'request',
          'response',
          'arpu',
          'arpDeu',
          'aipau',
          'newLogin',
          'newUserRate',
          'deu',
          'aipu',
          'requestFilledRate'
        ]
      }
      // 广告样式
      let positionarr = []
      if (this.searchquery.groupByList.includes('position_id') || this.searchquery.positionIdList.length > 0) {
        positionarr = [
          'newLogin',
          'newUserRate',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
      }
      // SDK版本
      const sdkShowAll = new Date(this.searchquery.dateEnd).getTime() >= new Date('2022-06-01').getTime()
      let sdkarr = []
      if (this.searchquery.groupByList.includes('sdk_version') || this.searchquery.sdkVersion) {
        // api数据 gap数据
        sdkarr = [
          'unitRevenue',
          'unitImpression',
          'unitEcpm',
          'unitClick',
          'unitCtr',
          'unitRequest',
          'unitFilledRate',
          'impressionGap',
          'clickGap',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
        if (!sdkShowAll) {
          sdkarr.push('dau')
          sdkarr.push('permeability')
          sdkarr.push('request')
          sdkarr.push('response')
          sdkarr.push('arpu')
          sdkarr.push('arpDeu')
          sdkarr.push('aipau')
          sdkarr.push('newLogin')
          sdkarr.push('newUserRate')
          sdkarr.push('deu')
          sdkarr.push('aipu')
          sdkarr.push('requestFilledRate')
        }
      }
      // 广告平台 账户
      let platarr = []
      if (
        this.searchquery.groupByList.includes('plat_id') ||
        this.searchquery.groupByList.includes('plat_account_id') ||
        this.searchquery.platIdList.length > 0
      ) {
        platarr = [
          'dau',
          'permeability',
          'request',
          'response',
          'arpu',
          'arpDeu',
          'aipau',
          'newLogin',
          'newUserRate',
          'deu',
          'aipu',
          'requestFilledRate'
        ]
      }
      //  应用版本
      let appVersionarr = []
      if (this.searchquery.groupByList.includes('app_version') || this.searchquery.appVersion) {
        // api数据 gap数据
        appVersionarr = [
          'unitRevenue',
          'unitImpression',
          'unitEcpm',
          'unitClick',
          'unitCtr',
          'unitRequest',
          'unitFilledRate',
          'impressionGap',
          'clickGap',
          'bidSuccessRate',
          'askPriceRequest',
          'askPriceFilledRate',
          'askPrice'
        ]
      }
      // 无维度
      let emptyarr = []
      if (this.searchquery.groupByList.length === 1) {
        emptyarr = ['bidSuccessRate', 'askPriceRequest', 'askPriceFilledRate', 'askPrice']
      }
      // 特殊处理广告成交额和利润率
      let noSearchArr = []
      if (
        !this.searchquery.groupByList.includes('plat_id') &&
        !this.searchquery.groupByList.includes('plat_account_id') &&
        this.searchquery.platIdList.length === 0 &&
        !this.searchquery.groupByList.includes('ad_source_id') &&
        this.searchquery.sourceIdList.length === 0
      ) {
        noSearchArr = ['advPrice', 'profitRate']
      }
      // 数组去重，取并集
      this.AngleShow = [
        ...new Set([
          ...apparr,
          ...placearr,
          ...grouparr,
          ...abtestarr,
          ...channelarr,
          ...sourcearr,
          ...positionarr,
          ...sdkarr,
          ...platarr,
          ...appVersionarr,
          ...emptyarr,
          ...noSearchArr
        ])
      ]
    },
    // 修改维度后，获取数据
    changeCustDimension (arr, isskip) {
      // 当前维度列表
      this.dimensionArr = arr
      this.searchquery.sortBy = ''
      this.searchquery.sortOrder = ''
      this.sorterItem = null
      this.groupByList = arr.map((item) => item.key)
      this.searchquery.groupByList = ['date', ...this.groupByList]
      const dimensions = arr.map((item) => {
        return {
          title: item.name,
          dataIndex: item.prop,
          width: item.width,
          align: 'left',
          // fixed: !(this.dimensionArr.length >= 5),
          fixed: false,
          scopedSlots: { customRender: item.prop }
        }
      })
      this.baseColumns = [
        {
          title: '日期',
          dataIndex: 'date',
          width: 180,
          align: 'left',
          fixed: true
        },
        ...dimensions
      ]
      this.columns = [...this.baseColumns, ...this.sureColumns]
      !isskip && this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    },
    //   导出报表
    async exportReport () {
      this.downLoading = true
      const arr1 = this.sureArr.map((item) => {
        return { prop: item.prop, name: item.label }
      })
      const arr2 = []
      this.baseColumns.forEach((item) => {
        arr2.push({ prop: item.dataIndex, name: item.title })
      })
      const sumArr = [...arr2, ...arr1]
      const codeList = []
      const nameList = []
      sumArr.forEach((item) => {
        codeList.push(item.prop)
        nameList.push(item.name)
      })
      const query = JSON.parse(JSON.stringify(this.searchquery))
      query.codeList = codeList
      query.nameList = nameList
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `sheetjs.xlsx`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    },
    // 获取到出报表数据
    exportToExcel (query) {
      return request.post('/report/downloadFullReport', query, {
        responseType: 'blob'
      })
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleCloseDimensionItem (index) {
      this.dimensionArr[index].checked = false
      this.dimensionArr.splice(index, 1)
      this.changeCustDimension(this.dimensionArr)
    }
  }
}
</script>

<style lang="less">
.synth-table-container {
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  .control {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      height: 36px;
      line-height: 36px;
      .dimension_list {
        display: flex;
        height: 100%;
        .dimension_item {
          padding: 0 15px;
          background: #eee;
          border-radius: 5px;
          font-size: 12px;
          margin-left: 20px;
          .dimension_close {
            margin-left: 5px;
            line-height: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .table {
    display: flex;
    padding: 20px;
    .app-name{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .synth_table {
      overflow: hidden;
      width: 100%;
      position: relative;
      .fixed_item {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ant-table-fixed-left table,
    .ant-table-fixed-right-right table {
      width: min-content;
    }
  }
  .pagination {
    padding: 10px 10px;
  }
}
.synth-popover {
  min-width: 240px;
  .synth-popover-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      // max-width: 240px;
      line-height: 30px;
    }
  }
}
</style>
